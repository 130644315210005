/** @format */
import React from "react";
import { useTranslation } from "react-i18next";

const TelVATEmail: React.FC = () => {
	const {t} = useTranslation("");
	// space in html character code is &nbsp;

	return (
		<div className="h-full w-full flex flex-col justify-center">
			<div className={"flex flex-row"}>
				<div className={"font-bold"}>{t("info.vat")}:</div>&nbsp;PIVA
				02449890504
			</div>
			<div className={"flex flex-row"}>
				<div className={"font-bold"}>{t("info.email")}:</div>&nbsp;
				<a
					href="mailto:booking@cascinacipressa.com"
					className={
						"text-primary-500 hover:text-primary-600 underline underline-offset-2"
					}
				>
					booking@cascinacipressa.com
				</a>
			</div>
			<div className={"flex flex-row"}>
				<div className={"font-bold"}>{t("info.phone")}:</div>&nbsp;
				<a
					href="tel:+3905711735608"
					className={
						"text-primary-500 hover:text-primary-600 underline underline-offset-2"
					}
				>
					+39 0571-1735608
				</a>
			</div>
		</div>
	);
}

export default TelVATEmail;
